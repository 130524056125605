import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type ExternalVendorsState = {
  vendorLocale?: api.VendorLocale,
  searchTerm?: string;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
  useLeadListFilters?: boolean;
  isInternalVendor?: boolean;
  isPreferredVendor?: boolean;
  isVendorInHubSpot?: boolean;
  minimumPercentageProjectedEligibleInvoiceSpend?: number;
  minimumEligibleInvoiceSpend?: number;
  minimumWeightedEligibleAveragePaymentTenor?: number;
  maximumWeightedEligibleAveragePaymentTenor?: number;
  currentExternalVendorId?: string;
  externalClientsPage?: number;
  externalClientsTotalPages?: number;
  externalClientsLimit?: PaginationDropdownLimit;
  externalClientsSortField?: string;
  externalClientsSortDirection?: api.SortDirection;
  externalInvoicesServicedByMesa?: boolean;
  externalInvoicesPage?: number;
  externalInvoicesTotalPages?: number;
  externalInvoicesLimit?: PaginationDropdownLimit;
  externalInvoicesSortField?: string;
  externalInvoicesSortDirection?: api.SortDirection;
};

const initialState: ExternalVendorsState = {
  searchTerm: '',
  vendorLocale: undefined,
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  useLeadListFilters: false,
  isInternalVendor: undefined,
  isPreferredVendor: undefined,
  isVendorInHubSpot: undefined,
  minimumPercentageProjectedEligibleInvoiceSpend: undefined,
  minimumEligibleInvoiceSpend: undefined,
  minimumWeightedEligibleAveragePaymentTenor: undefined,
  maximumWeightedEligibleAveragePaymentTenor: undefined,
  currentExternalVendorId: undefined,
  externalClientsPage: 1,
  externalClientsTotalPages: undefined,
  externalClientsLimit: { key: '10', value: 10 },
  externalClientsSortField: 'totalInvoiceSpendT6m',
  externalClientsSortDirection: api.SortDirection.DESCENDING,
  externalInvoicesServicedByMesa: undefined,
  externalInvoicesPage: 1,
  externalInvoicesTotalPages: undefined,
  externalInvoicesLimit: { key: '10', value: 10 },
  externalInvoicesSortField: 'paymentDate',
  externalInvoicesSortDirection: api.SortDirection.DESCENDING,
};

const externalVendorsSlice = createSlice({
  name: 'externalVendors',
  initialState,
  reducers: {
    updateSearchTerm(state: ExternalVendorsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updatePage(state: ExternalVendorsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: ExternalVendorsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: ExternalVendorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: ExternalVendorsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: ExternalVendorsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateVendorLocale(state: ExternalVendorsState, action: PayloadAction<api.VendorLocale | undefined>) {
      state.vendorLocale = action.payload;
    },
    updateUseLeadListFilters(state: ExternalVendorsState, action: PayloadAction<boolean>) {
      state.useLeadListFilters = action.payload;
    },
    updateIsInternalVendor(state: ExternalVendorsState, action: PayloadAction<boolean | undefined>) {
      state.isInternalVendor = action.payload;
    },
    updateIsPreferredVendor(state: ExternalVendorsState, action: PayloadAction<boolean | undefined>) {
      state.isPreferredVendor = action.payload;
    },
    updateIsVendorInHubSpot(state: ExternalVendorsState, action: PayloadAction<boolean | undefined>) {
      state.isVendorInHubSpot = action.payload;
    },
    updateMinimumPercentageProjectedEligibleInvoiceSpend(state: ExternalVendorsState, action: PayloadAction<number | undefined>) {
      state.minimumPercentageProjectedEligibleInvoiceSpend = action.payload;
    },
    updateMinimumEligibleInvoiceSpend(state: ExternalVendorsState, action: PayloadAction<number | undefined>) {
      state.minimumEligibleInvoiceSpend = action.payload;
    },
    updateMinimumWeightedEligibleAveragePaymentTenor(state: ExternalVendorsState, action: PayloadAction<number | undefined>) {
      state.minimumWeightedEligibleAveragePaymentTenor = action.payload;
    },
    updateMaximumWeightedEligibleAveragePaymentTenor(state: ExternalVendorsState, action: PayloadAction<number | undefined>) {
      state.maximumWeightedEligibleAveragePaymentTenor = action.payload;
    },
    updateCurrentExternalVendorId(state: ExternalVendorsState, action: PayloadAction<string>) {
      if (state.currentExternalVendorId !== action.payload) {
        state.currentExternalVendorId = action.payload;
        state.externalClientsPage = 1;
        state.externalClientsTotalPages = undefined;
        state.externalClientsLimit = { key: '10', value: 10 };
        state.externalClientsSortField = 'totalInvoiceSpendT6m';
        state.externalClientsSortDirection = api.SortDirection.DESCENDING;
      }
    },
    updateExternalClientsPage(state: ExternalVendorsState, action: PayloadAction<number>) {
      state.externalClientsPage = action.payload;
    },
    updateExternalClientsTotalPages(state: ExternalVendorsState, action: PayloadAction<number>) {
      state.externalClientsTotalPages = action.payload;
    },
    updateExternalClientsLimit(state: ExternalVendorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.externalClientsLimit = action.payload;
    },
    updateExternalClientsSortField(state: ExternalVendorsState, action: PayloadAction<string>) {
      state.externalClientsSortField = action.payload;
    },
    updateExternalClientsSortDirection(state: ExternalVendorsState, action: PayloadAction<api.SortDirection>) {
      state.externalClientsSortDirection = action.payload;
    },
    updateExternalInvoicesServicedByMesa(state: ExternalVendorsState, action: PayloadAction<boolean | undefined>) {
      state.externalInvoicesServicedByMesa = action.payload;
    },
    updateExternalInvoicesPage(state: ExternalVendorsState, action: PayloadAction<number>) {
      state.externalInvoicesPage = action.payload;
    },
    updateExternalInvoicesTotalPages(state: ExternalVendorsState, action: PayloadAction<number>) {
      state.externalInvoicesTotalPages = action.payload;
    },
    updateExternalInvoicesLimit(state: ExternalVendorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.externalInvoicesLimit = action.payload;
    },
    updateExternalInvoicesSortField(state: ExternalVendorsState, action: PayloadAction<string>) {
      state.externalInvoicesSortField = action.payload;
    },
    updateExternalInvoicesSortDirection(state: ExternalVendorsState, action: PayloadAction<api.SortDirection>) {
      state.externalInvoicesSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateUseLeadListFilters,
  updateIsInternalVendor,
  updateIsPreferredVendor,
  updateIsVendorInHubSpot,
  updateMinimumPercentageProjectedEligibleInvoiceSpend,
  updateMinimumEligibleInvoiceSpend,
  updateMinimumWeightedEligibleAveragePaymentTenor,
  updateMaximumWeightedEligibleAveragePaymentTenor,
  updateCurrentExternalVendorId,
  updateExternalClientsPage,
  updateExternalClientsTotalPages,
  updateExternalClientsLimit,
  updateExternalClientsSortField,
  updateExternalClientsSortDirection,
  updateVendorLocale,
  updateExternalInvoicesServicedByMesa,
  updateExternalInvoicesPage,
  updateExternalInvoicesTotalPages,
  updateExternalInvoicesLimit,
  updateExternalInvoicesSortField,
  updateExternalInvoicesSortDirection,
} = externalVendorsSlice.actions;

export default externalVendorsSlice.reducer;
