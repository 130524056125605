import React, { ReactElement, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { lazyWithRetries, LoadingScreen } from '@mesa-labs/mesa-ui';
import { types as api } from '@mesa-labs/mesa-api';

import CognitoRoute from './common/CognitoRoute';
import { AuthenticationGroup } from '../redux/slices/auth';
import ExternalClientsPageTemplate from '../components/common/ExternalClientsPageTemplate';
import { BusinessEntityScope } from '@mesa-labs/mesa-api/dist/types';
import ExternalClientVendorsPageTemplate from '../components/common/ExternalClientVendors';
import ExternalVendorsPageTemplate from '../components/common/ExternalVendorsPageTemplate';
import ExternalClientPageTemplate from '../components/common/ExternalClientPageTemplate';
import ExternalVendorPageTemplate from '../components/common/ExternalVendorPageTemplate';

const AcceleratedPayoutStatisticsPage = lazyWithRetries(() => import('../components/pages/AcceleratedPayout'));
const EligibleInvoicesPage = lazyWithRetries(() => import('../components/pages/EligibleInvoices'));
const AnomaliesPage = lazyWithRetries(() => import('../components/pages/Anomalies'));
const AnomalyPage = lazyWithRetries(() => import('../components/pages/Anomaly'));
const PartnerIntegrations = lazyWithRetries(() => import('../components/pages/PartnerIntegrations'));

const ClientsPage = lazyWithRetries(() => import('../components/pages/Clients'));
const ClientsClientsPage = lazyWithRetries(() => import('../components/pages/Clients/Clients'));
const ClientsClientPage = lazyWithRetries(() => import('../components/pages/Clients/Client'));
const ClientsClientMappingsPage = lazyWithRetries(() => import('../components/pages/Clients/ClientMappings'));
const ClientsClientMappingPage = lazyWithRetries(() => import('../components/pages/Clients/ClientMapping'));
const ClientsExclusionsPage = lazyWithRetries(() => import('../components/pages/Clients/ClientExclusions'));
const ClientsVendorClientRestrictionsPage = lazyWithRetries(() => import('../components/pages/Clients/VendorClientRestrictions'));
const ClientsConsolidatedClientsPage = lazyWithRetries(() => import('../components/pages/Clients/ConsolidatedClients'));
const VendorOverviewPage = lazyWithRetries(() => import('../components/pages/Vendor/Overview'));
const BankAccountsPage = lazyWithRetries(() => import('../components/pages/Vendor/BankAccounts'));
const VendorNotesPage = lazyWithRetries(() => import('../components/pages/Vendor/VendorNotes'));
const VendorAuditPage = lazyWithRetries(() => import('../components/pages/Vendor/VendorAudit'));
const VendorVerificationDocumentsPage = lazyWithRetries(() => import('../components/pages/Vendor/VerificationDocuments'));
const VendorAdjustmentGroupsPage = lazyWithRetries(() => import('../components/pages/Vendor/AdjustmentGroups'));
const VendorServiceAgreementsPage = lazyWithRetries(() => import('../components/pages/Vendor/ServiceAgreements'));
const VendorUserAccountsPage = lazyWithRetries(() => import('../components/pages/Vendor/UserAccounts'));
const VendorInvoicesPage = lazyWithRetries(() => import('../components/pages/Vendor/Invoices'));
const VendorInFlightsPage = lazyWithRetries(() => import('../components/pages/Vendor/InFlights'));
const VendorRestrictionsPage = lazyWithRetries(() => import('../components/pages/Vendor/Restrictions'));
const ProgramPage = lazyWithRetries(() => import('../components/pages/Program'));
const ProgramsPage = lazyWithRetries(() => import('../components/pages/Programs'));
const PartnerServiceAgreements = lazyWithRetries(() => import('../components/pages/PartnerServiceAgreements'));
const UpdatePartnerServiceAgreementPage = lazyWithRetries(() => import('../components/pages/UpdatePartnerServiceAgreementPage'));
const CreatePartnerServiceAgreementPage = lazyWithRetries(() => import('../components/pages/CreatePartnerServiceAgreementPage'));
const InvoiceDocumentPage = lazyWithRetries(() => import('../components/pages/InvoiceDocument'));

const HomePage = lazyWithRetries(() => import('../components/pages/Home'));
const SignInPage = lazyWithRetries(() => import('../components/pages/SignIn'));
const VendorsPage = lazyWithRetries(() => import('../components/pages/Vendors'));
const VendorPage = lazyWithRetries(() => import('../components/pages/Vendor'));
const OnboardingsPage = lazyWithRetries(() => import('../components/pages/Onboardings'));
const InvoicesPage = lazyWithRetries(() => import('../components/pages/Invoices'));
const InvoiceApprovalsPage = lazyWithRetries(() => import('../components/pages/InvoiceApprovals'));
const InvoicePage = lazyWithRetries(() => import('../components/pages/Invoice'));
const InvoiceLineItemsPage = lazyWithRetries(() => import('../components/pages/InvoiceLineItems'));
const InvoiceLineItemPage = lazyWithRetries(() => import('../components/pages/InvoiceLineItem'));
const OnboardingPage = lazyWithRetries(() => import('../components/pages/Onboarding'));
const Statistics = lazyWithRetries(() => import('../components/pages/Statistics'));
const ServiceAgreements = lazyWithRetries(() => import('../components/pages/ServiceAgreements'));
const CreateServiceAgreementPage = lazyWithRetries(() => import('../components/pages/CreateServiceAgreementPage'));
const CollectionsPage = lazyWithRetries(() => import('../components/pages/Collections'));
const CollectionPage = lazyWithRetries(() => import('../components/pages/Collection'));
const AdjustmentsPage = lazyWithRetries(() => import('../components/pages/Adjustments'));
const ProjectionsPage = lazyWithRetries(() => import('../components/pages/Projections'));
const TasksPage = lazyWithRetries(() => import('../components/pages/Tasks'));
const BatchSweepsPage = lazyWithRetries(() => import('../components/pages/BatchSweeps'));
const BatchSweepPage = lazyWithRetries(() => import('../components/pages/BatchSweep'));
const ReconciliationPage = lazyWithRetries(() => import('../components/pages/Reconciliation'));
const ReconciliationPayloadPage = lazyWithRetries(() => import('../components/pages/ReconciliationPayload'));
const SettingsPage = lazyWithRetries(() => import('../components/pages/Settings'));
const TransfersPage = lazyWithRetries(() => import('../components/pages/Transfers'));
const VendorServiceAgreementPage = lazyWithRetries(() => import('../components/pages/VendorServiceAgreement'));
const ExternalVendorStatistics = lazyWithRetries(() => import('../components/pages/ExternalVendorStatistics'));
const UpdateServiceAgreementPage = lazyWithRetries(() => import('../components/pages/UpdateServiceAgreementPage'));
const KeyPerformanceIndicatorsPage = lazyWithRetries(() => import('../components/pages/KeyPerformanceIndicators'));
const ConfigurationSettingsPage = lazyWithRetries(() => import('../components/pages/ConfigurationSettingsPage'));
const VendorDocumentPage = lazyWithRetries(() => import('../components/pages/VendorDocument'));
const EnablementPage = lazyWithRetries(() => import('../components/pages/Enablement'));
const AdjustmentPage = lazyWithRetries(() => import('../components/pages/Adjustment'));
const FacilitatorsPage = lazyWithRetries(() => import('../components/pages/Facilitators'));
const AdjustmentGroupPage = lazyWithRetries(() => import('../components/pages/AdjustmentGroup'));
const InvoiceAdjustmentApprovals = lazyWithRetries(() => import('../components/pages/InvoiceAdjustmentApprovals'));
const RevenuePage = lazyWithRetries(() => import('../components/pages/Revenue'));
const CohortsPage = lazyWithRetries(() => import('../components/pages/Cohorts'));
const AbandonedUsersPage = lazyWithRetries(() => import('../components/pages/AbandonedUsers'));
const AiAssistantPage = lazyWithRetries(() => import('../components/pages/AiAssistant'));
const VendorSurveysPage = lazyWithRetries(() => import('../components/pages/VendorSurveys'));

function AppRoutes(): ReactElement {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route
          path="/sign-in"
          element={(
            <CognitoRoute redirectToSignIn={false}>
              <SignInPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/"
          element={(
            <CognitoRoute>
              <HomePage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/vendors"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <VendorsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/external-vendors"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalVendorsPageTemplate businessEntityScope={BusinessEntityScope.External} />
            </CognitoRoute>
          )}
        />

        <Route
          path="/internal-vendors"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalVendorsPageTemplate businessEntityScope={BusinessEntityScope.Internal} />
            </CognitoRoute>
          )}
        />

        <Route
          path="/partners/:partnerId/external-vendors/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalVendorPageTemplate businessEntityScope={BusinessEntityScope.External} />
            </CognitoRoute>
          )}
        />

        <Route
          path="/partners/:partnerId/internal-vendors/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalVendorPageTemplate businessEntityScope={BusinessEntityScope.Internal} />
            </CognitoRoute>
          )}
        />

        <Route
          path="/external-clients"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalClientsPageTemplate businessEntityScope={BusinessEntityScope.External} />
            </CognitoRoute>
          )}
        />
        <Route
          path="/internal-clients"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalClientsPageTemplate businessEntityScope={BusinessEntityScope.Internal} />
            </CognitoRoute>
          )}
        />
        <Route
          path="/external-client-vendors"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalClientVendorsPageTemplate businessEntityScope={BusinessEntityScope.External} />
            </CognitoRoute>
          )}
        />
        <Route
          path="/internal-client-vendors"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalClientVendorsPageTemplate businessEntityScope={BusinessEntityScope.Internal} />
            </CognitoRoute>
          )}
        />
        <Route
          path="/partners/:partnerId/external-clients/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalClientPageTemplate businessEntityScope={api.BusinessEntityScope.External} />
            </CognitoRoute>
          )}
        />

        <Route
          path="/partners/:partnerId/internal-clients/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalClientPageTemplate businessEntityScope={api.BusinessEntityScope.Internal} />
            </CognitoRoute>
          )}
        />

        <Route
          path="/vendors/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <VendorPage />
            </CognitoRoute>
          )}
        >
          <Route
            index
            element={<VendorOverviewPage />}
          />

          <Route
            path="/vendors/:id/overview"
            element={<VendorOverviewPage />}
          />

          <Route
            path="/vendors/:id/bank-accounts"
            element={<BankAccountsPage />}
          />

          <Route
            path="/vendors/:id/notes"
            element={<VendorNotesPage />}
          />

          <Route
            path="/vendors/:id/invoices"
            element={<VendorInvoicesPage />}
          />

          <Route
            path="/vendors/:id/in-flights"
            element={<VendorInFlightsPage />}
          />

          <Route
            path="/vendors/:id/restrictions"
            element={<VendorRestrictionsPage />}
          />

          <Route
            path="/vendors/:id/audit"
            element={<VendorAuditPage />}
          />

          <Route
            path="/vendors/:id/verification-documents"
            element={<VendorVerificationDocumentsPage />}
          />

          <Route
            path="/vendors/:id/adjustment-groups"
            element={<VendorAdjustmentGroupsPage />}
          />

          <Route
            path="/vendors/:id/service-agreements"
            element={<VendorServiceAgreementsPage />}
          />

          <Route
            path="/vendors/:id/user-accounts"
            element={<VendorUserAccountsPage />}
          />
        </Route>

        <Route
          path="/clients"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ClientsPage />
            </CognitoRoute>
          )}
        >
          <Route
            index
            element={<Navigate to="/clients/clients" />}
          />

          <Route
            path="/clients/clients"
            element={<ClientsClientsPage />}
          />

          <Route
            path="/clients/clients/:id"
            element={<ClientsClientPage />}
          />

          <Route
            path="/clients/client-mappings"
            element={<ClientsClientMappingsPage />}
          />

          <Route
            path="/clients/client-mappings/partners/:partnerId/external-clients/:externalClientId"
            element={<ClientsClientMappingPage />}
          />

          <Route
            path="/clients/client-exclusions"
            element={<ClientsExclusionsPage />}
          />

          <Route
            path="/clients/vendor-restrictions"
            element={<ClientsVendorClientRestrictionsPage />}
          />

          <Route
            path="/clients/consolidated-clients"
            element={<ClientsConsolidatedClientsPage />}
          />
        </Route>

        <Route
          path="/programs"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ProgramsPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/programs/:code/partners/:partnerId"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ProgramPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/enablement"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <EnablementPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/invoices"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <InvoicesPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/invoice-approvals"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <InvoiceApprovalsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/invoices/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <InvoicePage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/invoices/:id/documents/:documentId"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <InvoiceDocumentPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/vendors/:vendorId/serviceAgreements/:serviceAgreementId"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <VendorServiceAgreementPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/partners/:partnerId/vendors/:vendorId/serviceAgreements/:serviceAgreementId"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <VendorServiceAgreementPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/vendors/:id/documents/:documentId"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <VendorDocumentPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/invoice-line-items"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <InvoiceLineItemsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/customer-batch-transfers"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <TransfersPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/settings"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <SettingsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/invoice-line-items/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <InvoiceLineItemPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/onboardings"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <OnboardingsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/onboardings/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <OnboardingPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/projections"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ProjectionsPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/tasks"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <TasksPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/statistics"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <Statistics />
            </CognitoRoute>
          )}
        />
        <Route
          path="/key-performance-indicators"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <KeyPerformanceIndicatorsPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/accelerated-payout-statistics"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AcceleratedPayoutStatisticsPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/partner-integrations"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <PartnerIntegrations />
            </CognitoRoute>
          )}
        />
        <Route
          path="/configuration-settings"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ConfigurationSettingsPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/external-vendor-statistics"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ExternalVendorStatistics />
            </CognitoRoute>
          )}
        />
        <Route
          path="/adjustment-groups/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AdjustmentGroupPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/adjustments"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AdjustmentsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/invoice-adjustment-approvals"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <InvoiceAdjustmentApprovals />
            </CognitoRoute>
          )}
        />

        <Route
          path="/revenue"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <RevenuePage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/cohorts"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <CohortsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/eligible-invoices"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <EligibleInvoicesPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/anomalies"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AnomaliesPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/anomalies/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AnomalyPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/adjustments/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AdjustmentPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/collections"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <CollectionsPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/collections/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <CollectionPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/reconciliation"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ReconciliationPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/batch-sweeps"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <BatchSweepsPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/batch-sweeps/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <BatchSweepPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/reconciliation/partners/:partnerId/payload/:payloadIdentifier"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ReconciliationPayloadPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/service-agreements"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <ServiceAgreements />
            </CognitoRoute>
          )}
        />

        <Route
          path="/service-agreements/create"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <CreateServiceAgreementPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/service-agreements/:id/update"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <UpdateServiceAgreementPage />
            </CognitoRoute>
          )}
        />

        <Route
          path={`/partner-service-agreements/partners/${api.Partners.JLL}`}
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <PartnerServiceAgreements partnerId={api.Partners.JLL} />
            </CognitoRoute>
          )}
        />

        <Route
          path={`/partner-service-agreements/partners/${api.Partners.JLL}/create`}
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <CreatePartnerServiceAgreementPage partnerId={api.Partners.JLL} />
            </CognitoRoute>
          )}
        />

        <Route
          path={`/partner-service-agreements/partners/${api.Partners.JLL}/versions/:id/update`}
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <UpdatePartnerServiceAgreementPage partnerId={api.Partners.JLL} />
            </CognitoRoute>
          )}
        />

        <Route
          path="/facilitators"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <FacilitatorsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/facilitators/:id"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <FacilitatorsPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/vendor-surveys"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <VendorSurveysPage />
            </CognitoRoute>
          )}
        />

        <Route
          path="/abandoned-users"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AbandonedUsersPage />
            </CognitoRoute>
          )}
        />
        <Route
          path="/ai-assistant"
          element={(
            <CognitoRoute
              authorizedRoles={[AuthenticationGroup.OPERATORS, AuthenticationGroup.FINANCIERS]}
            >
              <AiAssistantPage />
            </CognitoRoute>
          )}
        />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
