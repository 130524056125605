import React, { useMemo } from 'react';
import { Dropdown } from '@mesa-labs/mesa-ui';
import { DropdownOption } from '@mesa-labs/mesa-ui/dist/components/Dropdown';

const NoSelection = undefined;

type OptionalDropdownProps<T extends string | number> = {
  readonly label?: string;
  readonly options: DropdownOption<T>[];
  readonly selectedOption: T | undefined;
  readonly onChange: (value: T | undefined) => void;
}

function OptionalDropdown<T extends string | number>({ label, options, selectedOption, onChange }: OptionalDropdownProps<T>): React.ReactElement {
  const optionsWithOptional = useMemo(() => {
    return [
      { label: '(No Selection)', value: NoSelection as unknown as T },
      ...(options || [])
    ]
  }, [options]);

  return (
    <Dropdown
      label={label}
      options={optionsWithOptional}
      selectedOption={selectedOption !== undefined ? selectedOption : NoSelection}
      onChange={(value) => {
        const actualValue = (value as any).label ? (value as any).value : value;  // TODO: Underlaying Dropdown component sends the full option when the value is null/undefined
        onChange(actualValue !== NoSelection ? actualValue : undefined);
      }}
      minWidth='100%'
    />
  )
}

OptionalDropdown.defaultProps = {
  label: undefined,
  coerceValue: undefined
};

export default OptionalDropdown;

type OptionalBooleanDropdownProps = {
  readonly label?: string;
  readonly selectedOption: boolean | undefined;
  readonly onChange: (value: boolean | undefined) => void;
}

const BooleanOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' }
]

export function OptionalBooleanDropdown({ label, selectedOption, onChange }: OptionalBooleanDropdownProps): React.ReactElement {
  return (
    <OptionalDropdown
      label={label}
      options={BooleanOptions}
      selectedOption={selectedOption !== undefined ? `${selectedOption}` : undefined}
      onChange={(value) => onChange(value !== undefined ? value === 'true' : undefined)}
    />
  )
}