import { types as api } from '@mesa-labs/mesa-api';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  CardContainer,
  DownloadButton,
  PaginationFooter,
  ResourceCard,
  ResourceSection,
  Table,
} from '@mesa-labs/mesa-ui';
import { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';

import {
  exportExternalClientVendors,
  useGetExternalClientQuery, useGetExternalClientVendorsQuery,
} from '../../redux/api/externalVendors';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateExternalVendorsLimit, updateExternalVendorsPage, updateExternalVendorsSortDirection, updateExternalVendorsSortField, updateExternalVendorsTotalPages,
} from '../../redux/slices/externalClients';
import { DefaultPaginationLimits } from './PaginationLimits';
import { asExportFilter } from '../../utils';
import { ExternalClientVendorColumns } from './ExternalClientVendors';

const ClientPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type ExternalClientVendorsPageTemplate = {
  businessEntityScope: api.BusinessEntityScope;
};

function ExternalClientPageTemplate(props: ExternalClientVendorsPageTemplate): React.ReactElement | null {
  const { businessEntityScope } = props;
  const { partnerId, id: externalClientId } = useParams();
  const dispatch = useDispatch();
  const externalVendorsPage = useSelector((state) => state.externalClients.externalVendorsPage) || 1;
  const externalVendorsTotalPages = useSelector((state) => state.externalClients.externalVendorsTotalPages);
  const externalVendorsLimit = useSelector((state) => state.externalClients.externalVendorsLimit) || DefaultPaginationLimits[0];
  const externalVendorsSortField = useSelector((state) => state.externalClients.externalVendorsSortField) || 'totalInvoiceSpend';
  const externalVendorsSortDirection = useSelector((state) => state.externalClients.externalVendorsSortDirection) || api.SortDirection.DESCENDING;
  const partnerIdParam = parseInt(partnerId || '', 10);

  const {
    data: externalClient,
  } = useGetExternalClientQuery({
    partnerId: partnerIdParam,
    externalClientId: externalClientId!,
    businessEntityScope
  }, { skip: !externalClientId });

  const filter = {
    sortField: externalVendorsSortField,
    sortDirection: externalVendorsSortDirection,
    page: externalVendorsPage,
    limit: externalVendorsLimit.value,
    businessEntityScope
  };

  const {
    data: {
      data: externalVendors,
      total: totalExternalVendors,
    } = {},
  } = useGetExternalClientVendorsQuery({
    partnerId: partnerIdParam,
    externalClientId: externalClientId!,
    ...filter,
  }, { skip: !externalClientId });

  useEffect(() => {
    if (totalExternalVendors !== undefined) {
      const limitValue = externalVendorsLimit.value || externalVendorsLimit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateExternalVendorsTotalPages(Math.ceil(totalExternalVendors / limitValue)));
    }
  }, [totalExternalVendors, externalVendorsLimit]);

  const vendorRestrictions = useMemo(() => externalClient?.vendorRestrictions?.map(vr => `${vr.externalVendorId} (${vr.restrictionType})`).join(','), [externalClient]);

  if (!externalClient) {
    return null;
  }

  const externalClientColumns: (string | ResourceItemProp)[] = [
    { key: 'partnerName', label: 'Partner' },
    { key: 'externalClientId', label: 'External Client Id' },
    { key: 'defaultPaymentTermsDays', label: 'Default Payment Terms', width: '50%' },

    { key: 'segment', label: 'Client Segment' },
    { key: 'division', label: 'Division', width: '75%' },

    { key: 'exclusionCode', label: 'Exclusion Code' },
    { key: 'exclusionReason', label: 'Exclusion Reason' },
    { key: 'vendorRestrictions', label: 'Vendor Restrictions', width: '50%' },

    { key: 'weightedAveragePaymentTenorT6m', label: 'WAPT (T6M)' },
    { key: 'weightedAveragePaymentTenorT12m', label: 'WAPT (T12M)' },
    { key: 'weightedAverageLedgerLatenessT6m', label: 'WALL (T6M)' },
    { key: 'weightedAverageLedgerLatenessT12m', label: 'WALL (T12M)' },

    { key: 'totalInvoiceSpendT12m', label: 'Total Invoice Spend (T12M)', type: 'currency' },
    { key: 'totalInternalInvoiceSpendT12m', label: 'Total Signed Invoice Spend (T12M)', type: 'currency' },
    { key: 'internalInvoiceSpendConversionT12m', label: 'Invoice Spend Conversion (T12M)', type: 'percentage' },
    { key: 'totalServicedInvoiceSpendT12m', label: 'Total Funded Invoice Spend (T12M)', type: 'currency' },

    { key: 'totalVendors', label: 'Total Vendors' },
    { key: 'totalInternalVendors', label: 'Total Signed Vendors' },
    { key: 'internalVendorsConversion', label: 'Vendor Conversion', type: 'percentage' },
    { key: 'totalServicedVendorsT12m', label: 'Total Funded Vendors (T12M)' },

    { key: 'weightedEligibleAverageNet30AprT6m', type: 'percentage', label: 'Weighted Net 30 APR (T6M)' },
    { key: 'weightedEligibleAverageNet30AprT12m', type: 'percentage', label: 'Weighted Net 30 APR (T12M)' },
    { key: 'weightedEligibleAverageNet30YieldPricingT6m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T6M)' },
    { key: 'weightedEligibleAverageNet30YieldPricingT12m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T12M)' },

    { key: 'invoicesAccelerated', label: 'Invoices Accelerated (Count)' },
    { key: 'invoiceAmountAccelerated', label: 'Invoices Accelerated (Amount)', type: 'currency' },
    { key: 'revenueGenerated', label: 'Revenue Generated', type: 'currency' },

    { key: 'weightedAverageImportLateness', label: 'Weighted Average Import Lateness' },
    { key: 'weightedAveragePreFloatPeriod', label: 'Weighted Average Pre-Float Period' },
    { key: 'weightedAverageFloatPeriod', label: 'Weighted Average Float Period' },
    { key: 'weightedAverageDiscountRate', type: 'percentage', label: 'Weighted Discount Rate' },
    { key: 'weightedAverageApr', type: 'percentage', label: 'Weighted Average APR' },

    { key: 'internalPercentageFailingNet30', type: 'percentage', label: '% of Internal Invoices Failing Net-30' },
    { key: 'internalPercentageFailingNet60', type: 'percentage', label: '% of Internal Invoices Failing Net-60' },
    { key: 'internalPercentageSettledWithin3', type: 'percentage', label: '% of Internal Invoices Settled In 3' },

    { key: 'externalPercentageFailingNet30', type: 'percentage', label: '% of External Invoices Failing Net-30' },
    { key: 'externalPercentageFailingNet60', type: 'percentage', label: '% of External Invoices Failing Net-60' },
    { key: 'externalPercentageSettledWithin3', type: 'percentage', label: '% of External Invoices Settled In 3' }
  ];

  return (
    <ClientPageContainer>
      <CardContainer>
        <ResourceCard
          resource={{
            ...externalClient,
            partnerName: api.getPartnerName(externalClient.partnerId),
            vendorRestrictions,
            currency: 'USD', // TODO: MESA-1457: Multi-currency support
          }}
          title={externalClient.externalClientName || externalClient.externalClientId}
          items={externalClientColumns}
          columns={4}
        />
      </CardContainer>

      <ResourceSection
        title="External Vendors (T12M)"
        controls={(
          <DownloadButton
            text="Download CSV"
            data={() => exportExternalClientVendors(partnerIdParam, externalClient.externalClientId, {
              ...asExportFilter(filter),
            })}
            fileName="external-client-vendors.csv"
          />
        )}
      >
        <Table
          columns={ExternalClientVendorColumns}
          rows={
            (externalVendors || []).map((externalVendor) => ({
              ...externalVendor,
              partnerName: api.getPartnerName(externalVendor.partnerId),
              id: `${externalVendor.partnerId}:${externalVendor.externalVendorId}`,
              isInternalVendor: !!externalVendor.internalVendorId,
              currency: 'USD', // TODO: MESA-1457: Multi-currency support
            }))
          }
          currentSortColumn={externalVendorsSortField}
          currentSortDirection={externalVendorsSortDirection}
          setSortField={(field: string) => dispatch(updateExternalVendorsSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateExternalVendorsSortDirection(direction))}
          paginationComponent={(
            <PaginationFooter
              selectedLimit={externalVendorsLimit}
              onChange={(item) => {
                dispatch(updateExternalVendorsLimit(item));
                dispatch(updateExternalVendorsPage(1));
              }}
              currentPage={externalVendorsPage}
              totalPages={externalVendorsTotalPages || 0}
              onPrev={() => dispatch(updateExternalVendorsPage(externalVendorsPage - 1))}
              onNext={() => dispatch(updateExternalVendorsPage(externalVendorsPage + 1))}
            />
          )}
        />
      </ResourceSection>
    </ClientPageContainer>
  );
}

export default ExternalClientPageTemplate;
